import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  return (
    <Layout>
      <SEO title="404" />
      <h1>Not found :(</h1>
    </Layout>
  )
}
